import service from '@/until/api';

// 企业注册管理---分页查询
export function _FindAllPage(data) {
  return service({
    url: '/protocol-nacos/center/park/authorizedPark',
    method: 'GET',
    params: data
  });
}
