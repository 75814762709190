<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchObj.parkName"
            placeholder="园区名称"
            class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn" type="button" @click="getTableData()">
          搜索
        </button>
      </el-form-item>
      <el-form-item>
        <button class="centerBtn resetbtn" type="button" @click="reset">
          重置
        </button>
      </el-form-item>
    </el-form>
    <Table
        :data1="tableData"
        :columns1="columns"
        :total="total"
        @getAjax="getTableData"
        :loading="loading"
        :page="searchObj.page"
        :size="searchObj.size"
    />
    <!-- 重置密码 -->
    <el-dialog title="重置密码" :visible.sync="resetPwd" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="resetPwd = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- 确认删除 -->
    <el-dialog title="确认删除" :visible.sync="delTom" width="30%">
      <span>确认重置密码</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn">确 定</button>
        <button class="centerBtn resetbtn ml20" @click="delTom = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { _FindAllPage } from "@/api/sassManagement/parkOperations.js";
import { _JumpReturnToken } from "@/api/sassManagement/jumpReturnToken"

export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      resetPwd: false,
      formLabelWidth: "100px",
      total: 0,
      isAddAccount: false,
      delTom: false,
      isAdd: false,
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        roleCenterIds: [
          {
            required: true,
            message: "请选择角色",
            trigger: "blur",
          },
        ],
      },
      searchObj: {
        parkName: null,
        page: 1,
        size: 10,
      },
      tableData: [],
      columns: [
        {
          label: "园区名称",
          prop: "parkName",
        },
        {
          label: "所属企业",
          prop: "companyName",
        },
        {
          label: "企业授权账号",
          prop: "authorizedAccountName",
        },
        {
          label: "操作",
          render: (h, params) => {
            return h("div", [
              h(
                  "span",
                  {
                    class: "operationA",
                    on: {
                      click: () => {
                        this.jumpToken(params.row.accessParkAccountName)
                      },
                    },
                  },
                  "进入园区"
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    reset() {
      Object.assign(this.$data.searchObj, this.$options.data().searchObj);
      this.getTableData();
    },
    async getTableData(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.searchObj.page = pagination.page;
          this.searchObj.size = pagination.limit;
        }
      }
      let { success, message, data } = await _FindAllPage(this.searchObj);
      if (success == true) {
        this.tableData = data.list;
        this.total = data.total;
      }
      this.loading = false;
    },
    async jumpToken(param) {
      let xToken = localStorage.getItem('token')
      let { data } = await _JumpReturnToken({
        xToken: xToken,
        userId: param,
        type: "2"
      })
      console.log(data)
      this.tokenInfo = JSON.parse(JSON.stringify(data))
      window.open(
          window.urlConfig.baseUrl +
          "#/login?tokenStr=" +
          this.tokenInfo.jwtToken + "&token=" + this.tokenInfo.accessToken + "&form=company"
      );
    },
  },
};
</script>

<style scoped lang="less">
</style>
